import useSWR from "swr"
import fetcher from "data/fetcher"
import {PaginatedResponseOfCampaignEntity} from "model/paginatedResponseOfCampaignEntity";

export default function useActiveCampaigns (initialData?: any) {

	const { data, error } = useSWR("/campaign?status=active&limit=100&page=1", fetcher, {
		initialData: initialData
	})

	return {
		activeCampaigns: data as PaginatedResponseOfCampaignEntity,
		activeCampaignsIsLoading: !error && !data,
		activeCampaignsError: error
	}
}
