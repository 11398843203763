import * as React from 'react'
import { Box, CardContent, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { NextSeo } from 'next-seo'
import { useTranslation } from 'react-i18next'
import { StartonCard, StartonMarkdown, StartonTitle } from 'components/Core'
import { CampaignCard } from 'containers/Home'
import { wrapper } from 'stores'
import { UndrawCampaignEmptySVG } from 'components/SVG'
import starton from 'data/axios'
import platform from '../../.tmp/platform'
import { CampaignEntity } from 'model/campaignEntity'
import { PaginatedResponseOfCampaignEntity } from 'model/paginatedResponseOfCampaignEntity'
import useValidatedCampaigns from 'data/useValidatedCampaigns'
import useActiveCampaigns from 'data/useActiveCampaigns'
import useFinishedCampaigns from 'data/useFinishedCampaigns'

/*
|--------------------------------------------------------------------------
| CONTRACTS
|--------------------------------------------------------------------------
*/
interface IHomeProps {
	initialValidatedCampaigns: PaginatedResponseOfCampaignEntity
	initialActiveCampaigns: PaginatedResponseOfCampaignEntity
	initialFinishedCampaigns: PaginatedResponseOfCampaignEntity
}

/*
|--------------------------------------------------------------------------
| PAGE
|--------------------------------------------------------------------------
*/
function HomePage({ initialValidatedCampaigns, initialActiveCampaigns, initialFinishedCampaigns }: IHomeProps) {
	const theme = useTheme()
	const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
	const { t } = useTranslation()
	const { validatedCampaigns, validatedCampaignsIsLoading } = useValidatedCampaigns(initialValidatedCampaigns)
	const { activeCampaigns, activeCampaignsIsLoading } = useActiveCampaigns(initialActiveCampaigns)
	const { finishedCampaigns, finishedCampaignsIsLoading } = useFinishedCampaigns(initialFinishedCampaigns)

	if (!platform.frontSettings?.pages?.homepage) {
		return (
			<React.Fragment>
				<NextSeo title="Campaigns" description="Campaigns" />
				<StartonCard className="w-full text-center pt-8">
					<CardContent>
						<StartonTitle className="mb-10">{t('home.featured_campaigns')}</StartonTitle>
						<Box className="w-full flex flex-col items-center flex-wrap sm:items-start sm:flex-row justify-center align-top">
							{activeCampaigns?.items?.length > 0 ? (
								activeCampaigns?.items?.map((campaign: CampaignEntity) => (
									<CampaignCard key={campaign.id} {...campaign} />
								))
							) : (
								<Box className="flex flex-col items-center">
									<UndrawCampaignEmptySVG
										width={isDesktop ? '50%' : '75%'}
										height={isDesktop ? '50%' : '75%'}
									/>
									<Typography className="mt-6">{t('home.come_back_later')}</Typography>
								</Box>
							)}
						</Box>
					</CardContent>
				</StartonCard>
				{validatedCampaigns?.items?.length > 0 && (
					<StartonCard className="w-full text-center pt-8 mt-10">
						<CardContent>
							<StartonTitle className="mb-10">{t('home.future_campaigns')}</StartonTitle>
							<Box className="w-full flex flex-col items-center flex-wrap sm:items-start sm:flex-row justify-center align-top">
								{validatedCampaigns?.items?.map((campaign: CampaignEntity) => (
									<CampaignCard key={campaign.id} {...campaign} />
								))}
							</Box>
						</CardContent>
					</StartonCard>
				)}
				{finishedCampaigns?.items?.length > 0 && (
					<StartonCard className="w-full text-center pt-8 mt-10">
						<CardContent>
							<StartonTitle className="mb-10">{t('home.finished_campaigns')}</StartonTitle>
							<Box className="w-full flex flex-col items-center flex-wrap sm:items-start sm:flex-row justify-center align-top">
								{finishedCampaigns?.items?.map((campaign: CampaignEntity) => (
									<CampaignCard key={campaign.id} {...campaign} />
								))}
							</Box>
						</CardContent>
					</StartonCard>
				)}
			</React.Fragment>
		)
	} else {
		return (
			<React.Fragment>
				<NextSeo title="Homepage" description="Homepage" />
				<StartonCard className="w-full text-center pt-8 pb-12">
					<CardContent>
						<Box className="w-full flex flex-col items-center flex-wrap sm:items-start sm:flex-row justify-center align-top">
							<StartonMarkdown>{platform.frontSettings?.pages?.homepage || ''}</StartonMarkdown>
						</Box>
					</CardContent>
				</StartonCard>
			</React.Fragment>
		)
	}
}

/*
|--------------------------------------------------------------------------
| GET STATIC PROPS GET SERVER
|--------------------------------------------------------------------------
*/

export const getStaticProps = wrapper.getStaticProps(async () => {
	const finished = await starton.get('/campaign?status=finished&limit=100&page=1')
	const active = await starton.get('/campaign?status=active&limit=100&page=1')
	const validated = await starton.get('/campaign?status=validated&limit=100&page=1')

	return {
		props: {
			initialFinishedCampaigns: finished.data,
			initialActiveCampaigns: active.data,
			initialValidatedCampaigns: validated.data,
		},
		revalidate: 1,
	}
})

export default HomePage
