import * as React from 'react'
import { Box, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { IExcerptCampaign } from 'types'
import { StartonTitle } from 'components/Core'

/*
|--------------------------------------------------------------------------
| CONTRACT
|--------------------------------------------------------------------------
*/
type ICampaignCardContentProps = Pick<IExcerptCampaign, 'name' | 'hashtags' | 'description'>
type StyleProps = ICampaignCardContentProps
type StyleClassKey = 'content' | 'hashtags' | 'description'
type PropClasses = Record<StyleClassKey, string>

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const useStyles = makeStyles<Theme, StyleProps, StyleClassKey>((theme) => ({
	content: {
		padding: theme.spacing(4, 4, 0, 4),
	},
	hashtags: {
		color: theme.palette.text.primary,
		opacity: 0.35,
		fontWeight: 500,
		fontSize: '0.95rem',
		letterSpacing: 1.8,
		padding: '0px 5px',
		marginBottom: 20,
	},
	description: {
		textAlign: 'center',
		marginBottom: theme.spacing(3),
	},
}))

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const CampaignCardContent: React.FC<ICampaignCardContentProps> = (props: ICampaignCardContentProps) => {
	const classes: PropClasses = useStyles({} as StyleProps)

	return (
		<Box className={classes.content}>
			<StartonTitle size="small">{props.name}</StartonTitle>
			<Box className="flex flex-row justify-center items-center">
				{props.hashtags.map((hashtag) => (
					<Typography key={hashtag} className={classes.hashtags}>
						#{hashtag}
					</Typography>
				))}
			</Box>
			{props.description && (
				<Typography className={classes.description}>{String(props.description).substring(0, 55)}...</Typography>
			)}
		</Box>
	)
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export { CampaignCardContent }
