import * as React from 'react'
import { Box, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import organisation from '../../../../.tmp/organisation.json'
import { ICampaign } from 'types'
import { DiruptUtils } from '@dirupt/utils'

/*
|--------------------------------------------------------------------------
| CONTRACT
|--------------------------------------------------------------------------
*/
type ICampaignCardDetailsProps = Pick<
	ICampaign,
	'end' | 'start' | 'investors' | 'hardCap' | 'raised' | 'softCap' | 'status'
>
type StyleProps = ICampaignCardDetailsProps
type StyleClassKey = 'detailsContainer' | 'detailsTitle' | 'detailsValue'
type PropClasses = Record<StyleClassKey, string>

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const useStyles = makeStyles<Theme, StyleProps, StyleClassKey>((theme) => ({
	detailsContainer: {
		width: `100%`,
		'&:not(:first-child):not(:last-child)': {
			borderRight: `1px solid ${DiruptUtils.convertHexToRGVA(theme.palette.text.primary, 0.25)}`,
			borderLeft: `1px solid ${DiruptUtils.convertHexToRGVA(theme.palette.text.primary, 0.25)}`,
		},
	},
	detailsTitle: {
		color: theme.palette.text.primary,
		opacity: 0.35,
		letterSpacing: 1.8,
		fontSize: '0.75rem',
		fontWeight: 'bold',
		textTransform: 'uppercase',
	},
	detailsValue: {
		color: theme.palette.text.primary,
		letterSpacing: 0.56,
		fontSize: '1.8rem',
	},
}))

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const CampaignCardDetails: React.FC<ICampaignCardDetailsProps> = (props: ICampaignCardDetailsProps) => {
	const classes: PropClasses = useStyles({} as StyleProps)
	const days: number = React.useMemo<number>(() => {
		if (props.status === 'active') {
			const today = new Date()
			const end = new Date(props.end)
			const oneDay = 1000 * 60 * 60 * 24

			const left = Math.ceil((end.getTime() - today.getTime()) / oneDay)
			return left < 0 ? 0 : left
		} else if (props.status === 'validated') {
			const today = new Date()
			const start = new Date(props.start)
			const oneDay = 1000 * 60 * 60 * 24

			const left = Math.ceil((start.getTime() - today.getTime()) / oneDay)
			return left < 0 ? 0 : left
		} else return 0
	}, [props.end, props.status, props.start])
	const { t } = useTranslation()
	const settings = organisation.settings

	return (
		<Box className="flex flex-row justify-around items-center">
			<Box className={clsx('flex flex-col justify-center items-center', classes.detailsContainer)}>
				<Typography className={classes.detailsTitle}>
					{props.status === 'active' && t('campaign.days_left')}
					{props.status === 'finished' && t('campaign.days_left')}
					{props.status === 'validated' && t('campaign.start')}
				</Typography>
				<Typography className={classes.detailsValue}>{days}</Typography>
			</Box>

			<Box className={clsx('flex flex-col justify-center items-center', classes.detailsContainer)}>
				<Typography className={classes.detailsTitle}>{t('campaign.goal')}</Typography>
				<Typography className={classes.detailsValue}>
					{DiruptUtils.abbreviateNumber(props.hardCap || props.softCap, 2)} {settings.currency.symbol}
				</Typography>
			</Box>
			<Box className={clsx('flex flex-col justify-center items-center', classes.detailsContainer)}>
				<Typography className={classes.detailsTitle}>{t('campaign.raised')}</Typography>
				<Typography className={classes.detailsValue}>
					{DiruptUtils.abbreviateNumber(props.raised, 2)} {settings.currency.symbol}
				</Typography>
			</Box>
		</Box>
	)
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export { CampaignCardDetails }
