import useSWR from "swr"
import fetcher from "data/fetcher"
import {PaginatedResponseOfCampaignEntity} from "model/paginatedResponseOfCampaignEntity";

export default function useValidatedCampaigns (initialData?: any) {

	const { data, error } = useSWR("/campaign?status=validated&limit=100&page=1", fetcher, {
		initialData: initialData
	})

	return {
		validatedCampaigns: data as PaginatedResponseOfCampaignEntity,
		validatedCampaignsIsLoading: !error && !data,
		validatedCampaignsError: error
	}
}
