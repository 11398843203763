import * as React from 'react'
import { Box, CardActions, CardContent, CardMedia, Theme, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { IStartonCardProps, StartonCard } from 'components/Core/StartonCard/StartonCard'
import { IExcerptCampaign } from 'types'
import { StartonButton, StartonCampaignProgressBar, StartonLink } from 'components/Core'
import { CampaignCardContent, CampaignCardDetails } from 'containers/Home'
import { CampaignEntity } from 'model/campaignEntity'
import organisation from '../../../../.tmp/organisation.json'

/*
|--------------------------------------------------------------------------
| CONTRACT
|--------------------------------------------------------------------------
*/
export interface ICampaignCardProps extends CampaignEntity {
	cardProps?: Omit<IStartonCardProps, 'children'>
}
type StyleProps = ICampaignCardProps
type StyleClassKey =
	| 'campaignLink'
	| 'campaignCard'
	| 'cardContent'
	| 'media'
	| 'mediaOverlay'
	| 'mediaRaisedText'
	| 'cardActions'
	| 'campaignProgressBarContainer'
type PropClasses = Record<StyleClassKey, string>

/*
|--------------------------------------------------------------------------
| CONSTANTS
|--------------------------------------------------------------------------
*/
const BOTTOM_BUTTON_GAP = 28
const CAMPAIGN_CARD_MARGIN = 35

/*
|--------------------------------------------------------------------------
| STYLES
|--------------------------------------------------------------------------
*/
const useStyles = makeStyles<Theme, StyleProps, StyleClassKey>((theme) => ({
	campaignLink: {
		position: 'relative',
		transition: 'all 0.25s cubic-bezier(.17,.67,.83,.67)',
		width: `100%`,
		// maxWidth: 390,
		// '&:first-child': {
		// 	margin: `0px ${CAMPAIGN_CARD_MARGIN}px 0px ${CAMPAIGN_CARD_MARGIN * 2}px`,
		// },
		// '&:last-child': {
		// 	margin: `0px ${CAMPAIGN_CARD_MARGIN * 2}px 0px ${CAMPAIGN_CARD_MARGIN}px`,
		// },
		'&:hover, &:focus': {
			transform: 'scale(1.02)',
		},
		[theme.breakpoints.up('md')]: {
			width: `40%`,
			margin: `0px ${CAMPAIGN_CARD_MARGIN}px 80px !important`,
		},
		[theme.breakpoints.up('lg')]: {
			width: `25%`,
			margin: `0px ${CAMPAIGN_CARD_MARGIN}px 80px !important`,
		},
	},
	campaignCard: {
		paddingBottom: BOTTOM_BUTTON_GAP,
	},
	media: {
		height: 0,
		paddingTop: '56.25%', // 16:9
		borderTopLeftRadius: 20,
		borderTopRightRadius: 20,
	},
	mediaOverlay: {
		position: 'absolute',
		top: '80%',
		left: 0,
		right: 0,
		bottom: 0,
		background:
			'transparent linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%) 0% 0% no-repeat' +
			' padding-box',
	},
	mediaRaisedText: {
		position: 'absolute',
		top: '82%',
		left: 0,
		right: 0,
		bottom: 0,
		width: '100%',
		textAlign: 'center',
		color: 'white',
		fontWeight: 600,
		fontSize: '1.1rem',
		textTransform: 'uppercase',
	},
	cardContent: {
		padding: theme.spacing(0, 0, 4, 0),
	},
	cardActions: {
		padding: '0px 50px',
		position: 'absolute',
		width: '100%',
		bottom: -BOTTOM_BUTTON_GAP,
	},
	campaignProgressBarContainer: {
		marginTop: -11,
	},
}))

/*
|--------------------------------------------------------------------------
| COMPONENT
|--------------------------------------------------------------------------
*/
const CampaignCard: React.FC<ICampaignCardProps> = (props: ICampaignCardProps) => {
	const raisedPercentage: number = React.useMemo<number>(() => {
		const cap = props.hardCap || props.softCap
		return Math.floor((props.raised / cap) * 100)
	}, [props.raised, props.softCap])
	const classes: PropClasses = useStyles({} as StyleProps)
	const { t } = useTranslation()
	const settings = organisation.settings

	return (
		<StartonLink href={`/campaign/${props.id}`} className={clsx(classes.campaignLink, 'mb-16 sm:mb-0')}>
			<StartonCard {...props.cardProps} className={classes.campaignCard}>
				<Box className="relative">
					<CardMedia className={classes.media} image={props.highlightedImage} title={props.name} />
					<Box className={classes.mediaOverlay} />
					<Typography className={classes.mediaRaisedText}>
						{props.type === CampaignEntity.TypeEnum.INFINITE
							? `${props.raised} ${settings.currency.symbol} `
							: `${raisedPercentage}% `}
						{t('campaign.raised').toUpperCase()}
					</Typography>
				</Box>
				{props.type !== CampaignEntity.TypeEnum.INFINITE && (
					<StartonCampaignProgressBar
						raised={props.raised}
						softCap={props.softCap}
						hardCap={props.hardCap}
						className={classes.campaignProgressBarContainer}
					/>
				)}

				<CardContent className={classes.cardContent}>
					<CampaignCardContent name={props.name} hashtags={props.hashtags} description={props.description} />
					<CampaignCardDetails
						softCap={props.softCap}
						hardCap={props.hardCap}
						end={props.end}
						start={props.start}
						investors={props.investors}
						raised={props.raised}
						status={props.status}
					/>
				</CardContent>
				<CardActions className="flex flex-row justify-center" classes={{ root: classes.cardActions }}>
					<StartonButton fullWidth>{t('campaign.see_campaign')}</StartonButton>
				</CardActions>
			</StartonCard>
		</StartonLink>
	)
}

/*
|--------------------------------------------------------------------------
| EXPORT
|--------------------------------------------------------------------------
*/
export { CampaignCard }
