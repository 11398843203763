/**
 * Starton Tokenisation
 * API description
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CampaignPageEntity } from './campaignPageEntity';
import { InvoiceEntity } from './invoiceEntity';
import { ProjectHolderEntity } from './projectHolderEntity';
import { TokenEntity } from './tokenEntity';

export interface CampaignEntity { 
    id: string;
    name: string;
    description?: string;
    hashtags?: Array<string>;
    category: string;
    status: CampaignEntity.StatusEnum;
    currencies: Array<string>;
    type: CampaignEntity.TypeEnum;
    visibility: CampaignEntity.VisibilityEnum;
    hardCap?: string;
    softCap?: string;
    minimumPerInvestment: string;
    maximumPerInvestment?: string;
    startPrice?: string;
    currentPrice?: string;
    minimumPriceIncrement?: string;
    amountTokenToSold?: string;
    raised: string;
    pledged: string;
    investors: number;
    start: string;
    end: string;
    highlightedImage: string;
    metadata: any;
    page?: CampaignPageEntity;
    projectHolder?: ProjectHolderEntity;
    projectHolderId: string;
    invoices: Array<InvoiceEntity>;
    token: TokenEntity;
    tokenAction: CampaignEntity.TokenActionEnum;
    tokenActionPayload: any;
    tokenId: string;
    createdAt: string;
    updatedAt: string;
}
export namespace CampaignEntity {
    export type StatusEnum = 'draft' | 'validated' | 'finished' | 'active' | 'canceled' | 'archived';
    export const StatusEnum = {
        Draft: 'draft' as StatusEnum,
        Validated: 'validated' as StatusEnum,
        Finished: 'finished' as StatusEnum,
        Active: 'active' as StatusEnum,
        Canceled: 'canceled' as StatusEnum,
        Archived: 'archived' as StatusEnum
    };
    export type TypeEnum = 'INFINITE' | 'SOFT_HARD_CAP' | 'HARD_CAP' | 'SOFT_CAP' | 'WINNER_TAKE_ALL' | 'BATCH_AUCTION';
    export const TypeEnum = {
        INFINITE: 'INFINITE' as TypeEnum,
        SOFTHARDCAP: 'SOFT_HARD_CAP' as TypeEnum,
        HARDCAP: 'HARD_CAP' as TypeEnum,
        SOFTCAP: 'SOFT_CAP' as TypeEnum,
        WINNERTAKEALL: 'WINNER_TAKE_ALL' as TypeEnum,
        BATCHAUCTION: 'BATCH_AUCTION' as TypeEnum
    };
    export type VisibilityEnum = 'PUBLIC' | 'HIDDEN' | 'LOGGED';
    export const VisibilityEnum = {
        PUBLIC: 'PUBLIC' as VisibilityEnum,
        HIDDEN: 'HIDDEN' as VisibilityEnum,
        LOGGED: 'LOGGED' as VisibilityEnum
    };
    export type TokenActionEnum = 'mint' | 'transfer' | 'mint_1155' | 'mint_721' | 'mint_bundle';
    export const TokenActionEnum = {
        Mint: 'mint' as TokenActionEnum,
        Transfer: 'transfer' as TokenActionEnum,
        Mint1155: 'mint_1155' as TokenActionEnum,
        Mint721: 'mint_721' as TokenActionEnum,
        MintBundle: 'mint_bundle' as TokenActionEnum
    };
}