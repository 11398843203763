import useSWR from "swr"
import fetcher from "data/fetcher"
import {PaginatedResponseOfCampaignEntity} from "model/paginatedResponseOfCampaignEntity";

export default function useFinishedCampaigns (initialData?: any) {

	const { data, error } = useSWR("/campaign?status=finished&limit=100&page=1", fetcher, {
		initialData: initialData
	})

	return {
		finishedCampaigns: data as PaginatedResponseOfCampaignEntity,
		finishedCampaignsIsLoading: !error && !data,
		finishedCampaignsError: error
	}
}
